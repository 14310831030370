<script>
  import { onMount } from 'svelte';
  import { watchResize } from 'svelte-watch-resize';


  let mobile = false;

  //  MOBILE BREAKPOINT ///////////////
  const MOBILE_BREAKPOINT = 1025;
  /////////////////////////////////////

  const mobileToggle = node => {
    mobile = node.clientWidth < MOBILE_BREAKPOINT ? true : false;
  };

  onMount(() => {
    console.log('mounted, v2');
  });
</script>

<style>

  /*  v2 */

  body {
    margin: 0;
    font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-size: 14px;
    line-height: 1.5;
    color: #333;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 0.5em 0;
    font-weight: 400;
    line-height: 1.2;
  }

  h1 {
    font-size: 4em;
  }

  a {
    color: inherit;
  }

  code {
    font-family: menlo, inconsolata, monospace;
    font-size: calc(1em - 2px);
    color: #555;
    background-color: #f0f0f0;
    padding: 0.2em 0.4em;
    border-radius: 2px;
  }

  .highlight a {
    color: #00061c;
    background: #e1ddd3;
    -webkit-background-clip: none;
    -webkit-text-fill-color: #00061c;
  }

  @media (min-width: 400px) {
    body {
      font-size: 16px;
    }
  }

  @font-face {
    font-family: 'Amazon Ember Light';
    src: local('Amazon Ember Light'), local('Amazon-Ember-Light'),
      url('/fonts/AmazonEmber-Light.woff') format('woff');
  }

  @font-face {
    font-family: 'Amazon Ember';
    src: local('Amazon Ember'), local('Amazon-Ember'),
      url('/fonts/AmazonEmber-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Amazon Ember Bold';
    src: local('Amazon Ember Bold'), local('Amazon-Ember-Bold'),
      url('/fonts/AmazonEmber-Bold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Gotham';
    src: local('Gotham'), local('Gotham'),
      url('/fonts/GothamSSm-Book.otf') format('opentype');
  }

  @font-face {
    font-family: 'Gotham Medium';
    src: local('Gotham Medium'), local('Gotham-Medium'),
      url('/fonts/GothamSSm-Medium.otf') format('opentype');
  }

  @font-face {
    font-family: 'Gotham Medium Italic';
    src: local('Gotham Medium Italic'), local('Gotham-Medium-Italic'),
      url('/fonts/GothamSSm-MediumItalic.otf') format('opentype');
  }

  @font-face {
    font-family: 'Sectra Light Italic';
    src: local('Sectra Light Italic'), local('Sectra-Light-Italic'),
      url('/fonts/GT-Sectra-Display-Light-Italic.woff') format('woff');
  }

  @font-face {
    font-family: 'Sectra';
    src: local('Sectra'), local('Sectra'),
      url('/fonts/GT-Sectra-Display-Light.woff') format('woff');
  }

  @font-face {
    font-family: 'Sectra Medium Italic';
    src: local('Sectra Medium Italic'), local('Sectra-Medium-Italic'),
      url('/fonts/GT-Sectra-Display-Medium-Italic.woff') format('woff');
  }

  main {
    position: relative;
    max-width: 1440px;
    background-color: white;
    padding: 0em 0 4em 0;
    margin: 0 auto;
    box-sizing: border-box;
    background-color: #021a35;
    height: auto;
  }

  .ember {
    font-family: 'Amazon Ember';
  }

  .ember-light {
    font-family: 'Amazon Ember Light';
  }

  .ember-bold {
    font-family: 'Amazon Ember Bold';
  }

  .gotham {
    font-family: 'Gotham Medium';
  }

  .gotham-italic {
    font-family: 'Gotham Medium Italic';
  }

  .sectra-italic {
    font-family: 'Sectra Light Italic';
  }

  .sectra {
    font-family: 'Sectra';
  }

  .sectra-medium-italic {
    font-family: 'Sectra Medium Italic';
  }

  .gradient-text {
    background: #b58a6e;
    background: -webkit-linear-gradient(
      45deg,
      #b58a6e 0%,
      #ba9272 25%,
      #bd9a7c 50%,
      #dcc9a1 75%,
      #e3ded5 100%
    );
    background: -moz-linear-gradient(
      45deg,
      #b58a6e 0%,
      #ba9272 25%,
      #bd9a7c 50%,
      #dcc9a1 75%,
      #e3ded5 100%
    );
    background: linear-gradient(
      45deg,
      #b58a6e 0%,
      #ba9272 25%,
      #bd9a7c 50%,
      #dcc9a1 75%,
      #e3ded5 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  #top,
  #bottom,
  #left,
  #right {
    background: rgb(181, 138, 110);
    background: linear-gradient(
      45deg,
      rgba(181, 138, 110, 1) 0%,
      rgba(186, 146, 114, 1) 14%,
      rgba(189, 154, 124, 1) 28%,
      rgba(220, 201, 161, 1) 43%,
      rgba(227, 222, 213, 1) 57%,
      rgba(220, 201, 161, 1) 71%,
      rgba(189, 154, 124, 1) 85%,
      rgba(186, 146, 114, 1) 100%
    );
    position: absolute;
    z-index: 999;
  }
  #left,
  #right {
    top: 2em;
    bottom: 2em;
    width: 1px;
  }
  #left {
    left: 2em;
  }
  #right {
    right: 2em;
  }

  #top,
  #bottom {
    left: 2em;
    right: 2em;
    height: 1px;
  }
  #top {
    top: 2em;
  }
  #bottom {
    bottom: 2em;
  }

  .considerHeader {
    width: 100%;
    margin: 0em auto 0em;
    text-indent: -9000px;
    background: url(/img/ConsiderAmazonOriginal.svg); 
    /*background: url(https://amazon-interactive.dmlib.in/cnc/img/ConsiderAmazonOriginal.svg); */
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 100%;
    position: relative;
    overflow: hidden;
    padding: 9em 0 0em 0;
    /*top: 2em;*/
    max-width:400px;
    margin-bottom: -.5em;
  }

  h1 {
    text-align: center;
   font-family: "Sectra";
    font-size: 5.5em;
    line-height: 1.1em;
    background: #B58A6E;
    background: -webkit-linear-gradient(45deg, #B58A6E 0%, #BA9272 25%, #BD9A7C 50%, #DCC9A1 75%, #e3ded5 100%);
    background: -moz-linear-gradient(45deg, #B58A6E 0%, #BA9272 25%, #BD9A7C 50%, #DCC9A1 75%, #e3ded5 100%);
    background: linear-gradient(45deg, #B58A6E 0%, #BA9272 25%, #BD9A7C 50%, #DCC9A1 75%, #e3ded5 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: -75px 0 0 0px;
    background-size: 100%;
      padding-bottom: 30px;
      padding-left: .25em;
  }

  #boxLine {
    display: block;
    margin: 8px auto;
    text-align: center;
  }

  .including:before {
    content: "";
    position: absolute;
    width: 50%;
       top: 10px;
           left: -10px;

    position: relative;
      margin: 15px 0 15px 0;
          text-align: center;
          display: inline-block;
          height: 2px;
      background: rgb(181,138,110);
      background: linear-gradient(45deg, rgba(181,138,110,1) 0%, rgba(186,146,114,1) 14%, rgba(189,154,124,1) 28%, rgba(220,201,161,1) 43%, rgba(227,222,213,1) 57%, rgba(220,201,161,1) 71%, rgba(189,154,124,1) 85%, rgba(186,146,114,1) 100%);  
  }

   .including:after {
    content: "";
    width: 45%;
    position: absolute;
    right: -0.5px;
       top: 10px;

      margin: 15px 0 15px 10px;
          text-align: center;
          padding-left: 10px;
          display: inline-block;
          height: 2px;
      background: rgb(181,138,110);
      background: linear-gradient(45deg, rgba(181,138,110,1) 0%, rgba(186,146,114,1) 14%, rgba(189,154,124,1) 28%, rgba(220,201,161,1) 43%, rgba(227,222,213,1) 57%, rgba(220,201,161,1) 71%, rgba(189,154,124,1) 85%, rgba(186,146,114,1) 100%);  
  }
  .including {

    position: relative;
    display: inline-block;
    margin: 50px 100px;

     text-align: center;    
     background-color:#021a35;
/*    background: #B58A6E;
*/    background: -webkit-linear-gradient(90deg, #B58A6E 0%, #BA9272 25%, #BD9A7C 50%, #DCC9A1 75%, #e3ded5 100%);
    background: -moz-linear-gradient(90deg, #B58A6E 0%, #BA9272 25%, #BD9A7C 50%, #DCC9A1 75%, #e3ded5 100%);
    background: linear-gradient(90deg, #B58A6E 0%, #BA9272 25%, #BD9A7C 50%, #DCC9A1 75%, #e3ded5 100%);
    background-size: 100%;
    background-position: 0%;
    font-family: 'Amazon Ember';
    letter-spacing: 5px;
    text-transform: uppercase;
    color: #000;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 100%;
    line-height: -10px;
    position: relative;
    top: -20px;
  }

  .header_center {
    font-size: 2rem;
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    grid-column-gap: 1.2rem;
    align-items: center;
    background-color:#021a35;
/*    background: #B58A6E;
*/    background: -webkit-linear-gradient(90deg, #B58A6E 0%, #BA9272 25%, #BD9A7C 50%, #DCC9A1 75%, #e3ded5 100%);
    background: -moz-linear-gradient(90deg, #B58A6E 0%, #BA9272 25%, #BD9A7C 50%, #DCC9A1 75%, #e3ded5 100%);
    background: linear-gradient(90deg, #B58A6E 0%, #BA9272 25%, #BD9A7C 50%, #DCC9A1 75%, #e3ded5 100%);
    background-size: 100%;
    background-position: 0%;
    font-family: 'Amazon Ember';
    font-size: 1em;
    letter-spacing: 5px;
    text-transform: uppercase;
    color: #000;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-bottom: 1.5em;
}

.header_center::before,
.header_center::after {
    content: "";
    display: inline-block;
    height: 2px;
          background: rgb(181,138,110);
      background: linear-gradient(45deg, rgba(181,138,110,1) 0%, rgba(186,146,114,1) 14%, rgba(189,154,124,1) 28%, rgba(220,201,161,1) 43%, rgba(227,222,213,1) 57%, rgba(220,201,161,1) 71%, rgba(189,154,124,1) 85%, rgba(186,146,114,1) 100%);  
}

.header_center img {
  width: 100%;
  max-width: 125px;
}

  h1 sup {
    font-size: 18px;
    padding: 0 0 0 5px;
    margin-right: -5px;
  }

  .movieBox {
    margin-bottom: 5px;
    text-align: center;
  }

  .movieBox img {
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }

  .gridLayout {
    display: block;
    width: 90%;
    margin: 0 auto;
    text-align: left;
    padding: 0;
  }

  .gridLayout a {
        margin-right: 10px;
  }

  .gridLayout li {
    display: inline-block;
    vertical-align: top;
    width: 11.48%;
  }

  .gridLayout a:last-child {
    margin-right: 0px;
  }

  .gridLayout h3 {
     text-align: center;
    padding: 0em 0 0px 0;
    background-color:#021a35;
/*    background: #B58A6E;
*/    background: -webkit-linear-gradient(-90deg, #B58A6E 0%, #BA9272 25%, #BD9A7C 50%, #DCC9A1 75%, #e3ded5 25%);
    background: -moz-linear-gradient(-90deg, #B58A6E 0%, #BA9272 25%, #BD9A7C 50%, #DCC9A1 75%, #e3ded5 25%);
    background: linear-gradient(-90deg, #B58A6E 0%, #BA9272 25%, #BD9A7C 50%, #DCC9A1 75%, #e3ded5 25%);
    background-size: 100%;
    background-position: 0%;
    font-family: 'Amazon Ember Bold';
    font-size: 9px;
    color: #000;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 100%;
    max-width: 150px;
    margin: 0 auto;
    text-align: center;
  }

  .gridLayout h4 {
     text-align: center;
     margin: 2px 0 0 0;
    padding: 0em 0 0px 0;
    background-color:#021a35;
/*    background: #B58A6E;
*/    background: -webkit-linear-gradient(-90deg, #B58A6E 0%, #BA9272 25%, #BD9A7C 50%, #DCC9A1 75%, #e3ded5 25%);
    background: -moz-linear-gradient(-90deg, #B58A6E 0%, #BA9272 25%, #BD9A7C 50%, #DCC9A1 75%, #e3ded5 25%);
    background: linear-gradient(-90deg, #B58A6E 0%, #BA9272 25%, #BD9A7C 50%, #DCC9A1 75%, #e3ded5 25%);
    background-size: 100%;
    background-position: 0%;
    font-family: 'Amazon Ember';
    font-size: 8px;
    color: #000;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 100%;
  }

  h3.breakSpace {
    padding-bottom: 8px;
  }

    h4.breakSpace {
    position: relative;
    top:-8px;
  }

  #footer {
    width: 100%; 
    text-align: center;
        margin-top: -60px;
    padding-bottom: 10px;
  }

  .consider {
    max-width: 215px;
    display: inline-block;
    margin-right: 16px;
  }

   .watch {
    max-width: 215px;
    display: inline-block;
  }

   .consider img, .watch img {
    width: 100%;
  }



  @media (max-width: 1365px) {
    .gridLayout li {
    display: inline-block;
    vertical-align: top;
    width: 11.4%;
  }
  }

  @media (max-width: 1330px) {

      .gridLayout li {
        padding-bottom: 16px;
      }

      .gridLayout {
        margin: 0 auto;
        text-align: center;
      }

       .gridLayout li {
        display: inline-block;
        vertical-align: top;
        width: 20%;
      }

      .gridLayout a:nth-child(5) {
        margin-right: 0;
      }

      #footer {
    margin-top: 0;

  }

  @media (max-width: 1280px) {
   
       

      .gridLayout a:nth-child(5) {
        margin-right: 0;
      }
  }

  @media (max-width: 1195px) {
   

      .gridLayout a:nth-child(5) {
        margin-right: 0;
      }
  }

  @media (max-width: 1125px) {
   
     

      .gridLayout a:nth-child(5) {
        margin-right: 0;
      }
  }

  @media (max-width: 1075px) {
   
      

      .gridLayout a:nth-child(5) {
        margin-right: 0;
      }
  }

  @media (max-width: 1028px) {
      
        .gridLayout {
          text-align: center;
              width: 80%;
        }

        .header_center {
          width: 100%;
          text-align: center;
          margin: 0 auto;
        }

       .gridLayout li {
        display: inline-block;
        vertical-align: top;
        width: 33%;
            padding-bottom: 30px;
      }

      .gridLayout a:nth-child(3), .gridLayout a:nth-child(5), .gridLayout a:nth-child(7) {
        margin-right: 0;
      }

      #footer {
        margin-bottom: 30px;
      }

      #footer .consider {
        margin-bottom: 25px;
      }

      .gridLayout h3 {
        font-size: 14px;
        max-width: 100%;
      }

      .gridLayout h3 br {
        display: none;
      }

      .gridLayout h4 {
        font-size: 10px;
        max-width: 100%;
      }

      h1 {
            font-size: 5em;
            margin-bottom: -5px;
            margin-top: -50px;
                line-height: 1em;
      }
  }

   @media (max-width: 950px) {
    

    h1 sup {
    font-size: 20px;
    padding: 0 0 0 5px;
  }

   }

   @media (max-width: 800px) {
   

    .header_center {
          font-size: .75em;
    }

   
   }

    @media (max-width: 570px) {
   .gridLayout {
        margin: 0 auto;
   }



   h1 {
    font-size: 5em;
    margin-bottom: -5px;
    margin-top: -25px;
    line-height: .9em;
    padding-left: 0;
   }
   .gridLayout li {
        display: inline-block;
        vertical-align: top;
        width:90%;
        margin: 0 auto;
      }

      .gridLayout a {
           margin-right: 0;
            margin-left: 0; 
      }

      .gridLayout li .movieBox {
        max-width: 80%;
        margin: 0 auto 16px;
      }

      .header_center {
        font-size: 1em;

    }

      .considerHeader {
      max-width: 90%;
          padding: 4em 0 0em 0;
    }

    .consider {
    display: block;
    text-align: center;
    margin: 0px auto 10px;
  }

   .watch {
    display: block;
    text-align: center;
    margin: 0 auto;
  }

   .gridLayout h3 {
        font-size: 12px;
        max-width: 100%;
      }

      .gridLayout h3 br {
        display: none;
      }

      .gridLayout h4 {
        font-size: 10px;
        max-width: 100%;
      }


   }

  @media (min-width: 640px) {
    main {
      max-width: none;
    }

    #footer .consider {
        margin-bottom: 30px;
      }
  }

  h4.lastBreak {
    margin-bottom: -9px;
   }

</style>

<!-- STYLE -->
<main use:watchResize={mobileToggle}>
  
  <!-- MOBILE -->
  {#if mobile}
    <div id="main">
      <a href="/"><div class="considerHeader animate fadeInLeft two">Consider Amazon</div></a>
      <h1>Emmy<sup>&reg;</sup><br>Nominees</h1>
      
      <div id="footer">
      <div class="consider">
        <a href="https://consideramazon.com/home" target="_blank"><img src="img/ConsiderAmazon.COM_Button-01.svg"></a>
      </div>
      <div class="watch">
        <a href="https://www.amazon.com/gp/video/offers/ref=dvm_us_dl_sl_go_brsa_mkw_svHxbIxqC-dc_pcrid_448130500925/143-6535013-7954512?ie=UTF8&gclid=EAIaIQobChMIjdy8xuPv8QIVK_HjBx2lCggVEAAYASAAEgIoO_D_BwE&mrntrk=slid__pgrid_29008589832_pgeo_1014226_x__ptid_kwd-45697133742" target="_blank"><img src="img/PRIMEVIDEO_Button-01.svg"></a>
      </div>
    </div>

      <ul class="gridLayout">
        <div class="header_center"><img src="img/IncludingMobile-01.png"></div>
        <a href="https://consideramazon.com/title/the-underground-railroad" target="_blank">
          <li>
            <div class="movieBox">
              <img src="img/UGRR_KEY_ART.jpg" />
            </div>
            <h3 class="breakSpace">Outstanding Limited Series</h3>
            <h3 class="breakSpace">Outstanding Directing</h3>
            <h4 class="breakSpace">Limited Series</h4>
            <h3 class="breakSpace">Outstanding Cinematography</h3>
            <h4 class="breakSpace">Limited Series</h4>
            <h3 class="breakSpace">Outstanding Music Composition</h3>
            <h4 class="breakSpace">Limited Series (Dramatic Score)</h4>
            <h3 class="breakSpace">Outstanding Casting</h3>
            <h4 class="breakSpace">Limited Series</h4>
            <h3 class="breakSpace">Outstanding Sound Editing</h3>
            <h4 class="breakSpace">Limited Series</h4>
            <h3 class="breakSpace">Outstanding Sound Mixing</h3>
            <h4 class="breakSpace">Limited Series</h4>
          </li>
        </a>
        <a href="https://consideramazon.com/title/the-boys" target="_blank">
          <li>
            <div class="movieBox">
              <img src="img/THBY_KEY_ART.jpg" />
            </div>
            <h3 class="breakSpace">Outstanding Drama Series</h3>
            <h3 class="breakSpace">Outstanding Writing</h3>
            <h4 class="breakSpace">Drama Series</h4>
            <h3 class="breakSpace">Outstanding Original<br> Music and Lyrics</h3>
            <h3 class="breakSpace">Outstanding Sound Mixing</h3>
            <h4 class="breakSpace">Drama Series (One Hour)</h4>
            <h3 class="breakSpace">Outstanding Special<br> Visual Effects</h3>
            <h4 class="breakSpace lastBreak">In a Season</h4>
          </li>
        </a>
        <a href="https://consideramazon.com/title/sylvies-love" target="_blank">
          <li>
            <div class="movieBox">
              <img src="img/SYLV_KEY_ART.jpg" />
            </div>
            <h3>Outstanding<br> Television Movie</h3>
          </li>
        </a>
         <a href="https://consideramazon.com/title/uncle-frank" target="_blank">
          <li>
            <div class="movieBox">
              <img src="img/UNCF_KEY_ART.jpg" />
            </div>
            <h3>Outstanding<br> Television Movie</h3>
          </li>
        </a>
        <a href="https://consideramazon.com/title/all-in-the-fight-for-democracy-1" target="_blank">
          <li>
            <div class="movieBox">
              <img src="img/UVSD_KEY_ART.jpg" />
            </div>
            <h3>Outstanding Writing</h3>
            <h4>For a Nonfiction Program</h4>
          </li>
        </a>
        <a href="https://consideramazon.com/title/small-axe" target="_blank">
          <li>
            <div class="movieBox">
              <img src="img/SMLL_KEY_ART.jpg" />
            </div>
            <h3>Outstanding Cinematography</h3>
            <h4>For a Limited Series</h4>
          </li>
        </a>
        <a href="https://consideramazon.com/title/savage-x-fenty-show-vol-2" target="_blank">
          <li>
            <div class="movieBox">
              <img src="img/TURQ_KEY_ART.jpg" />
            </div>
            <h3>Outstanding Choreography</h3>
            <h4>For Variety Programming</h4>
          </li>
        </a>
        
        <a href="https://consideramazon.com/title/vikings" target="_blank">
          <li>
            <div class="movieBox">
              <img src="img/VIKINGS_KEY_ART.jpg" />
            </div>
            <h3>Outstanding Special<br> Visual Effects</h3>
            <h4>In a Single Episode</h4>
          </li>
        </a>
    </div>
    

    <!-- END MOBILE -->
  {:else}
    <!-- DESKTOP -->
      <div id="left" />
  <div id="right" />
  <div id="top" />
  <div id="bottom" />
    <div id="main">
      <a href="/"><div class="considerHeader animate fadeInLeft two">Consider Amazon</div></a>
      <h1>Emmy<sup>&reg;</sup> Nominees</h1>
      
      <ul class="gridLayout">
        <div class="header_center"><img src="img/IncludingMobile-01.png"></div>
        

        <a href="https://consideramazon.com/title/all-in-the-fight-for-democracy-1" target="_blank">
          <li>
            <div class="movieBox">
              <img src="img/UVSD_KEY_ART.jpg" />
            </div>
            <h3>Outstanding Writing</h3>
            <h4>For a Nonfiction Program</h4>
          </li>
        </a>
        <a href="https://consideramazon.com/title/the-boys" target="_blank">
          <li>
            <div class="movieBox">
              <img src="img/THBY_KEY_ART.jpg" />
            </div>
            <h3 class="breakSpace">Outstanding Drama Series</h3>
            <h3 class="breakSpace">Outstanding Writing</h3>
            <h4 class="breakSpace">Drama Series</h4>
            <h3 class="breakSpace">Outstanding Original<br>Music and Lyrics</h3>
            <h3 class="breakSpace">Outstanding Sound Mixing</h3>
            <h4 class="breakSpace">Drama Series (One Hour)</h4>
            <h3 class="breakSpace">Outstanding Special<br>Visual Effects</h3>
            <h4 class="breakSpace lastBreak">In a Season</h4>
          </li>
        </a>
        <a href="https://consideramazon.com/title/savage-x-fenty-show-vol-2" target="_blank">
          <li class="fenty">
            <div class="movieBox">
              <img src="img/TURQ_KEY_ART.jpg" />
            </div>
            <h3>Outstanding<br> Choreography</h3>
            <h4>For Variety Programming</h4>
          </li>
        </a>
        <a href="https://consideramazon.com/title/small-axe" target="_blank">
          <li>
            <div class="movieBox">
              <img src="img/SMLL_KEY_ART.jpg" />
            </div>
            <h3>Outstanding Cinematography</h3>
            <h4>For a Limited Series</h4>
          </li>
        </a>
        <a href="https://consideramazon.com/title/sylvies-love" target="_blank">
          <li>
            <div class="movieBox">
              <img src="img/SYLV_KEY_ART.jpg" />
            </div>
            <h3>Outstanding<br>Television Movie</h3>
          </li>
        </a>
        <a href="https://consideramazon.com/title/uncle-frank" target="_blank">
          <li>
            <div class="movieBox">
              <img src="img/UNCF_KEY_ART.jpg" />
            </div>
            <h3>Outstanding<br>Television Movie</h3>
          </li>
        </a>
        <a href="https://consideramazon.com/title/the-underground-railroad" target="_blank">
          <li>
            <div class="movieBox">
              <img src="img/UGRR_KEY_ART.jpg" />
            </div>
            <h3 class="breakSpace">Outstanding Limited Series</h3>
            <h3 class="breakSpace">Outstanding Directing</h3>
            <h4 class="breakSpace">Limited Series</h4>
            <h3 class="breakSpace">Outstanding Cinematography</h3>
            <h4 class="breakSpace">Limited Series</h4>
            <h3 class="breakSpace">Outstanding Music Composition</h3>
            <h4 class="breakSpace">Limited Series (Dramatic Score)</h4>
            <h3 class="breakSpace">Outstanding Casting</h3>
            <h4 class="breakSpace">Limited Series</h4>
            <h3 class="breakSpace">Outstanding Sound Editing</h3>
            <h4 class="breakSpace">Limited Series</h4>
            <h3 class="breakSpace">Outstanding Sound Mixing</h3>
            <h4 class="breakSpace">Limited Series</h4>
          </li>
        </a>
        <a href="https://consideramazon.com/title/vikings" target="_blank">
          <li>
            <div class="movieBox">
              <img src="img/VIKINGS_KEY_ART.jpg" />
            </div>
            <h3>Outstanding Special<br>Visual Effects</h3>
            <h4>In a Single Episode</h4>
          </li>
        </a>
    </div>
    <div id="footer">
      <div class="consider">
        <a href="https://consideramazon.com/home" target="_blank"><img src="img/ConsiderAmazon.COM_Button-01.svg"></a>
      </div>
      <div class="watch">
        <a href="https://www.amazon.com/gp/video/offers/ref=dvm_us_dl_sl_go_brsa_mkw_svHxbIxqC-dc_pcrid_448130500925/143-6535013-7954512?ie=UTF8&gclid=EAIaIQobChMIjdy8xuPv8QIVK_HjBx2lCggVEAAYASAAEgIoO_D_BwE&mrntrk=slid__pgrid_29008589832_pgeo_1014226_x__ptid_kwd-45697133742" target="_blank"><img src="img/PRIMEVIDEO_Button-01.svg"></a>
      </div>
    </div>
  {/if}
</main>
